<template>
  <div>
    <div class="d-flex justify-start align-center mb-8">
      <div>
        <v-avatar
          color="primary"
          class="v-avatar-light-bg primary--text mr-2"
          size="35"
        >
          <v-icon
            dense
            color="primary"
          >
            {{ icons.mdiTownHall }}
          </v-icon>
        </v-avatar>
      </div>
      <div>
        <div v-if="isLoadingInstance">
          <v-progress-circular
            color="primary"
            :size="30"
            indeterminate
          ></v-progress-circular>
        </div>
        <div v-else>
          <span class="d-block text-caption text-capitalize black--text">{{ instance.company_types_uuid.type }}</span>
          <span
            class="d-block text-subtitle-1 font-weight-bold black--text"
            style="line-height: 1.5rem !important"
          >
            {{ instance.name }}
          </span>
        </div>
      </div>
    </div>

    <v-card>
      <DataTablePagination
        :page="page"
        :total-items="totalItems"
        :headers="headers"
        :items="students"
        :total-pages.sync="totalPages"
        :is-loading="isLoadingStudent"
        :no-add-button="noAddButton"
        :no-search="true"
        :filter-class-upper="filterClassUpper"
        :classes="classes"
        title="Peserta Didik / Siswa"
        subtitle="Halaman untuk menampilkan daftar siswa"
        @change-page="paginationHandler"
        @live-search="searchHandler"
        @filter-classes="filterClasses"
      >
      </DataTablePagination>
    </v-card>
  </div>
</template>

<script>
import { mdiClose, mdiDotsVertical, mdiTownHall } from '@mdi/js'
import DataTablePagination from '../components/DataTablePagination.vue'

export default {
  name: 'UserInstance',
  components: {
    DataTablePagination,
  },
  data() {
    return {
      dialog: '',
      search: '',
      confirmDialog: false,
      confirmCancels: false,
      validateDelete: null,
      isLoadingData: true,
      isLoadingStudent: false,
      isLoadingButton: false,
      addFormDialog: false,
      editFormDialog: false,
      confirmDeleteDialog: false,
      noAddButton: true,
      page: 1,
      totalPages: 1,
      totalItems: 1,
      itemsPerPage: 10,
      filterClassUpper: true,
      icons: {
        mdiDotsVertical,
        mdiTownHall,
        mdiClose,
      },
      headers: [
        { text: 'No.', value: 'index' },
        { text: 'Nama Lengkap', value: 'name' },
        { text: 'Kelas', value: 'school.name' },
        { text: 'Email', value: 'email' },
        { text: 'Jenis Kelamin', value: 'gender' },
        { text: 'Tanggal Lahir', value: 'birthday' },
        { text: 'No. HP', value: 'phone_number' },
        { text: 'Provinsi', value: 'profile.province.name' },
        { text: 'Kecamatan', value: 'profile.district.name' },
      ],
      students: [],
      classes: [],
      serviceTransactionCompany: 'transactioncompany',
      serviceClass: 'classes',
      serviceStudent: 'student',
      instance: {},
      isLoadingInstance: false,
      serviceInstance: 'instance',
      instanceUuid: JSON.parse(localStorage.getItem('user')).user.company_uuid,
      filterQuery: {
        search: '',
        class_uuid: '',
      },
    }
  },
  watch: {
    page: {
      handler() {
        this.getStudent(this.filterQuery)
      },
    },
  },
  created() {
    this.getDetailInstance()
    this.getStudent()
    this.getClasses()
  },
  methods: {
    async getDetailInstance() {
      this.isLoadingInstance = true

      await this.$services.ApiServices.get(this.serviceInstance, this.instanceUuid).then(
        ({ data }) => {
          this.instance = data.data
          this.isLoadingInstance = false
        },
        err => {
          console.error(err)
          this.isLoadingInstance = false
        },
      )

      this.isLoadingInstance = false
    },

    async getStudent(params = {}) {
      this.isLoadingStudent = true

      await this.$services.ApiServices.get(this.serviceStudent, this.instanceUuid, {
        ...params,
        page: this.page,
      }).then(
        ({ data }) => {
          this.students = data.data.map((type, index) => ({
            ...type,
            index: index + 1,
          }))
          this.totalPages = data.meta.last_page
          this.totalItems = data.meta.total
          this.isLoadingStudent = false
        },
        err => {
          console.error(err)
          this.isLoadingStudent = false
        },
      )
      this.isLoadingStudent = false
    },

    async getClasses(params = {}) {
      this.isLoadingClass = true

      await this.$services.ApiServices.list(this.serviceClass, {
        ...params,
        per_page: 'all',
        page: this.page,
        company_uuid: this.instanceUuid,
      }).then(
        ({ data }) => {
          this.classes = data.data
          this.isLoadingClass = false
        },
        err => {
          console.error(err)
          this.isLoadingClass = false
        },
      )

      this.isLoadingClass = false
    },

    filterClasses(uuid) {
      this.filterQuery.class_uuid = uuid
      if (!uuid) this.getStudent()
      else this.getStudent(this.filterQuery)
    },

    searchHandler(search) {
      this.filterQuery.search = search
      this.page = 1
      this.getStudent(this.filterQuery)
    },

    paginationHandler(pageNumber) {
      this.page = pageNumber
    },

    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style scoped>
.book-packet-header ul li {
  list-style: none;
  margin-bottom: 1rem;
}

.search-book > .v-input__control > .v-text-field__details {
  display: none;
}

.search-book > .v-input__control > .v-input__slot {
  margin: 0 !important;
}
</style>
